export const PRODUCTCODES = {
  ULYSSE: '5601-22'
};
export const WAREHOUSECODES = {
  WAREHOUSE_0040: '0040'
};

export const THRESHOLDS = [5, 7.5, 10];

export const SERVICEORDER_STATUSES = {
  0: 'Submitted',
  1: 'Registered',
  2: 'Rejected',
  3: 'InQueue',
  4: 'RepairStarted',
  5: 'Finished',
  6: 'CreditNote',
  7: 'VoucherWaitingInternally',
  8: 'VoucherApproved',
  9: 'VoucherWaitingForConsumer',
  10: 'VoucherAccepted',
  11: 'VoucherSent',
  12: 'Closed',
  13: 'RepairWaitingForCustomer',
  14: 'VoucherDeclined',
  15: 'RepairDeclinedByCustomer',
  16: 'VoucherToBeSent',
  17: 'NotReceived',
  18: 'Received',
  19: 'WaitingForMaterial',
  20: 'Draft'
};

export const SERVICEORDER_RESOLUTION = {
  0: 'Unknown',
  1: 'FreeRepair',
  2: 'Voucher',
  3: 'Credit',
  4: 'RepairByHour',
  5: 'RepairFixedPrice',
  6: 'RejectNotReparable',
  7: 'RejectNotWashed',
  8: 'RejectConsumer',
  9: 'Replace',
  10: 'Refund',
  11: 'ExternalRepair',
  12: 'ExternalPayment',
  13: 'CashPoint',
  99: 'Other'
};

export const TRACKINGSTRINGS = {
  PRODUCT_CLICKED: 'Product Clicked',
  PRODUCT_VIEWED: 'Product Viewed',
  PRODUCT_ADDED_CART: 'Product Added',
  PRODUCT_ADDED_WISHLIST: 'Product Added to Wishlist',
  PRODUCT_REMOVED_CART: 'Product Removed',
  PRODUCT_REMOVED_WISHLIST: 'Product Removed from Wishlist',
  PRODUCT_WISHLIST_TO_CART: 'Wishlist Product Added to Cart',
  CART_VIEWED: 'Cart Viewed',
  CLICKED_JOIN_LOYALTY: 'Clicked Join Loyalty',
  CHECKOUT_STARTED: 'Checkout Started',
  SIZEGUIDE_CLICKED: 'Size Guide Clicked',
  AVAILABILITY_CLICKED: 'Availability Clicked',
  STORE_AVAILABILITY_CLICKED: 'Store Availability Clicked',
  PRODUCT_SEARCHED: 'Product Searched',
  SEARCH_STARTED: 'Search Started',
  SEARCH_RESULT_VIEWED: 'Search Result Viewed',
  SEARCH_RESULT_CLICKED: 'Search Result Clicked',
  MENU_ITEM_CLICKED: 'Menu Item Clicked',
  NOTIFY_ME_CLICKED: 'Notify me Clicked',
  FILTER_HEADER_CLICKED: 'Filter header clicked',
  PRODUCT_GUIDE_VIEWED: 'Product Guide Viewed',
  COLLECTION_SITE_VIEWED: 'Collection Site Viewed',
  BLOCK_CLICKED: 'block_clicked',
  EVENT_DETAIL_CLICKED: 'event_detail_clicked',
  EVENT_SIGNUP_CLICKED: 'event_signup_clicked',
  EVENT_SUBMIT_CLICKED: 'event_submit_clicked',
  AD_CLICKED: 'ad_clicked'
};

export const GENDERSTRINGS = [
  '(M/W)',
  '(M)',
  '(W)',
  "M's",
  "W's",
  'Unisex',
  'M´s',
  'Jr´s',
  'W´s'
];

export const SUPERSCRIPT = {
  flex1: '1',
  flex2: '2',
  flex3: '3',
  warm1: '1',
  warm2: '2',
  warm3: '3',
  dri1: '1',
  dri2: '2',
  dri3: '3',
  down950: '950',
  down900: '900',
  down800: '800',
  downDWR800: '800',
  down850: '850',
  down750: '750',
  warmwool1: '1',
  aero100: '100',
  aerodownproof: 'downproof',
  recon: '',
  primaloft170: '170',
  primaloft400: '400',
  alpha90: '90',
  alpha60: '60',
  warmwool2: '2',
  hiloflex200: '200'
};

export const DISPLAY_OPTIONS = {
  full: 'u-md-sizeFull',
  wide: 'u-md-size2of3',
  half: 'u-md-size1of2',
  narrow: 'u-md-size1of3'
}; // TODO UPDATE THESE TO BOOTSTRAP, or be removed?

export const SIZES = {
  CONTENT_MAX_WIDTH: 1332
};

export const IMAGE_SIZES = {
  DEFAULT: {
    WIDTH: 342,
    HEIGHT: 468
  },
  CART: {
    WIDTH: 342,
    HEIGHT: 468
  },
  PRODUCTLIST: {
    WIDTH: 342,
    HEIGHT: 468
  },
  PRODUCT: {
    WIDTH: 554,
    HEIGHT: 756
  },
  NEWPRODUCTCARDS: {
    WIDTH: 396,
    HEIGHT: 495
  }
}; // can this be removed?

export const PROPORTIONS = {
  DEFAULT: {
    SM: 4 / 5,
    MD: 16 / 9,
    LG: 16 / 9
  },
  MEDIUM: {
    SM: 5 / 4,
    MD: 16 / 7,
    LG: 16 / 7
  },
  SMALL: {
    SM: 5 / 4,
    MD: 16 / 4,
    LG: 16 / 4
  },
  XSMALL: {
    SM: 5 / 4,
    MD: 16 / 3,
    LG: 16 / 3
  },
  MODEL: {
    SM: 1 / 2,
    MD: 1 / 2,
    LG: 1 / 2
  },
  VIDEO: {
    SM: 16 / 9,
    MD: 16 / 9,
    LG: 16 / 9
  }
};

export const BREAKPOINTS = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1350
};

export const SRCSET_STEPS = [
  190, 220, 275, 320, 410, 500, 764, 1000, 1324, 1569, 1956, 2138, 3200, 4000,
  5000
];

export const BGCOLOR = {
  DEFAULT: 'fcfcfc',
  BLACK: '1d1d1d',
  LIGHTER_GRAY: 'f5f5f5',
  LIGHT_GRAY: 'DADADA',
  DARK_GRAY: '747474'
}; // Should be removed. use colorHelper globals instead

// LOOKUP FOR SORTING FILTERS
export const COLOR_LOOKUP = [
  { name: 'Pink', hex: '#FF759A' },
  { name: 'Purple', hex: '#D1A6C9' },
  { name: 'Darkred', hex: '#D2002A' },
  { name: 'Red', hex: '#D13E3E' },
  { name: 'Orange', hex: '#F59333' },
  { name: 'Yellow', hex: '#DFB524' },
  { name: 'Green', hex: '#717340' },
  { name: 'Darkgreen', hex: '#565447' },
  { name: 'Brightgreen', hex: '#32B141' },
  { name: 'Navy', hex: '#3C4765' },
  { name: 'Blue', hex: '#0281EC' },
  { name: 'Lightblue', hex: '#3CADD4' },
  { name: 'Black', hex: '#2C2F32' },
  { name: 'Darkgrey', hex: '#747474' },
  { name: 'Grey', hex: '#BEBEBE' },
  { name: 'White', hex: '#F5F3F1' },
  { name: 'Brown', hex: '#F59333' },
  { name: 'Khaki', hex: '#c3b091' },
  { name: 'Beige', hex: '#FAE0C9' }
];
export const FIT_LOOKUP = ['Slim', 'Technical', 'Regular', 'Loose'];
export const SIZE_LOOKUP = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];

export const BAG_TYPES = {
  CART: 1,
  WISHLIST: 2,
  ORDERHISTORY: 3
}; // is this still in use?

export const WISHLIST_TYPES = {
  DEFAULT: 0, // an actual wishlist
  B2BSALESREP: 1, // used for b2b where sales rep can make a "suggestion"
  OUTFIT: 2, // Outfit guide
  CX: 3, // CX can make a wishlist for a user
  STORE: 4, // A store can make a wishlist for a user
  NOTIFY: 5 // A type to notify for back in stock
};

export const ANIMATION_CONSTANTS = {
  SLOW: 0.75,
  NORMAL: 0.5,
  FAST: 0.25
}; // might not be neccessary

// METRIC CONVERSION
export const METRIC_IMPERIAL = {
  INCH: 0.3937,
  FEET: 12,
  CENTIMETER: 2.54,
  FEET_CM: 30.48,
  POUND: 2.20462,
  KG: 0.45359237
};

// CLAIMS ID's
export const DAMAGE_CONSTANTS = {
  JACKET: '1',
  MIDLAYER: '4',
  SHIRT: '6',
  VEST: '5',
  ONEPIECE: '3',
  PANTS: '2',
  SHORTS: '7',
  BACKPACK: '10',
  ACCESSORIES: '11',

  HOOD: '01',
  UPPER_FRONT_BODY: '02',
  UPPER_BACK_BODY: '03',
  RIGHT_SHOULDER: '04',
  LEFT_SHOULDER: '05',
  RIGHT_ARM: '06',
  LEFT_ARM: '07',
  LOWER_FRONT_BODY: '08',
  LOWER_BACK_BODY: '09',
  UPPER_RIGHT_LEG: '10',
  UPPER_LEFT_LEG: '11',
  LOWER_RIGHT_LEG: '12',
  LOWER_LEFT_LEG: '13',
  RIGHT_FOOD: '14',
  LEFT_FOOD: '15',
  RIGHT_HAND: '16',
  LEFT_HAND: '17',
  WHOLE_PRODUCT: '18',

  FRONT: 1,
  BACK: 2
};

export const CONCEPT_NAMES = {
  fjora: 'fjørå',
  29: '/29',
  more: 'møre',
  norrona: 'norrøna'
};

export const PRODUCT_IMAGE_ORDER = {
  ModelShotMain: 'ModelShotImageUrl',
  ModelBackShotMain: 'ModelBackShotImageUrl',
  ModelFrontLeftMain: 'ModelFrontLeftImageUrl',
  PackShot: 'ImageUrl',
  PackShotBack: 'PackShotBackImageUrl',
  PackShotFrontLeft: 'PackShotFrontLeftImageUrl',
  ModelExtra1: 'ModelExtra1ImageUrl',
  ModelExtra2: 'ModelExtra2ImageUrl',
  ModelFrontWomens: 'ModelFrontWomensUrl',
  ModelBackWomens: 'ModelBackWomensUrl',
  ModelFrontLeftWomens: 'ModelFrontLeftWomensUrl'
};

export const STATUS_ICONS = {
  ICON_BLUE: { name: 'Dot', color: '#4FCAFF' },
  ICON_ORANGE: { name: 'Dot', color: '#ff9f51' },
  ICON_GREEN: { name: 'Dot', color: '#00FF99' },
  ICON_GRAY: { name: 'Dot', color: '#DADADA' },
  ICON_RED: { name: 'Dot', color: '#E7807B' },
  ICON_CHECKED: { name: 'Check' }
};

export const MARKETS = [
  { name: 'AUT', code: 'AT' },
  { name: 'CAN', code: 'CA' },
  { name: 'DNK', code: 'DK' },
  { name: 'FIN', code: 'FI' },
  { name: 'FRA', code: 'FR' },
  { name: 'DEU', code: 'DE' },
  { name: 'JPN', code: 'JP' },
  { name: 'NOR', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'SJM', code: 'SJ' },
  { name: 'SWE', code: 'SE' },
  { name: 'GBR', code: 'GB' },
  { name: 'USA', code: 'US' },
  { name: 'NL', code: 'NL' },
  { name: 'CHE', code: 'CH' }
];

export const B2B_PREORDER_EXCEPTIONS_FW2526 = [
  '1834-24 5566',
  '1838-24 3010',
  '1820-24 3010',
  '1810-20 5531',
  '1861-20 3010',
  '1842-24 6646',
  '1845-24 3010',
  '2648-24 3516',
  '2647-24 3516',
  '2653-24 1107',
  '2669-25 3516',
  '2681-25 3516',
  '2644-24 1107',
  '2665-24 1107',
  '2658-25 3516',
  '2640-24 3516',
  '2654-24 1107',
  '2679-25 3516',
  '5209-22 1107',
  '5210-22 1107',
  '5211-22 1107',
  '2406-21 2295',
  '2407-21 6640',
  '5282-25 7718',
  '1603-23 5630',
  '1613-23 5630',

  '5282-25 7718',
  '5282-25 7718',
  '1219-19 1261',

  '1070-25 8017',
  '1219-19 1008',

  '5276-25 8016'
];
export const B2B_WORKBOOK_EXCEPTIONS_FW2526 = [
  '1834-24 5566',
  '1838-24 3010',
  '1820-24 3010',
  '1810-20 5531',
  '1861-20 3010',
  '1842-24 6646',
  '1845-24 3010',
  '2648-24 3516',
  '2647-24 3516',
  '2653-24 1107',
  '2669-25 3516',
  '2681-25 3516',
  '2644-24 1107',
  '2665-24 1107',
  '2658-25 3516',
  '2640-24 3516',
  '2654-24 1107',
  '2679-25 3516',
  '5209-22 1107',
  '5210-22 1107',
  '5211-22 1107',
  '2406-21 2295',
  '2407-21 6640',
  '5282-25 7718',

  '5282-25 7718',
  '5282-25 7718',
  '1219-19 1261',

  '1070-25 8017',
  '1219-19 1008',

  '5276-25 8016'
];

export const B2B_CANCELLED_LIST = [
  '1001-20 1003',
  '1001-22 2295',
  '1001-22 2336',
  '1001-24 8874',
  '1003-23 5501',
  '1004-22 1002',
  '1004-22 2295',
  '1017-20 1003',
  '1017-20 2336',
  '1017-20 3397',
  '1017-20 7718',
  '1019-24 3397',
  '1020-24 1002',
  '1020-24 2019',
  '1020-24 8874',
  '1021-24 2019',
  '1021-24 2336',
  '1022-24 2019',
  '1023-24 1003',
  '1024-20 5501',
  '1024-24 1180',
  '1025-24 1004',
  '1025-24 1180',
  '1025-24 1208',
  '1026-20 3397',
  '1026-24 1180',
  '1026-24 4411',
  '1031-20 6747',
  '1032-20 1004',
  '1032-20 2295',
  '1032-20 6748',
  '1043-20 1003',
  '1043-20 4411',
  '1043-20 7718',
  '1047-20 7718',
  '1064-17 7760',
  '1171-12 7718',
  '1201-23 2308',
  '1201-23 8874',
  '1202-24 7718',
  '1203-19 5625',
  '1205-23 3001',
  '1205-23 8874',
  '1206-23 3516',
  '1206-23 6749',
  '1207-23 7718',
  '1208-23 3001',
  '1213-23 2295',
  '1213-23 3001',
  '1217-23 3516',
  '1219-19 6749',
  '1221-19 3516',
  '1229-23 2295',
  '1229-23 5625',
  '1230-23 2295',
  '1230-23 3001',
  '1605-22 3321',
  '1605-22 7758',
  '1613-23 7718',
  '1613-24 2336',
  '1616-24 3413',
  '1620-23 6609',
  '1625-22 2290',
  '1625-22 5620',
  '1626-22 2295',
  '1627-19 3413',
  '1627-19 7718',
  '1627-22 8874',
  '1628-22 2314',
  '1629-19 7718',
  '1802-17 2295',
  '1802-17 7718',
  '1803-23 5646',
  '1806-20 2336',
  '1806-20 5630',
  '1807-19 7718',
  '1807-23 2380',
  '1810-20 3001',
  '1810-20 5630',
  '1810-20 7718',
  '1810-21 2336',
  '1810-21 7721',
  '1810-23 7718',
  '1811-21 2295',
  '1811-22 7718',
  '1811-23 2011',
  '1811-23 6000',
  '1811-23 7718',
  '1811-23 8016',
  '1812-22 2295',
  '1813-24 2295',
  '1814-24 3516',
  '1815-20 7718',
  '1815-22 2295',
  '1815-22 3001',
  '1815-22 8874',
  '1816-22 1180',
  '1816-22 2380',
  '1816-22 8874',
  '1820-24 2011',
  '1821-22 2017',
  '1821-22 2295',
  '1821-22 3319',
  '1821-22 5646',
  '1822-22 1180',
  '1823-22 6610',
  '1827-24 2295',
  '1833-20 5646',
  '1833-20 7718',
  '1835-20 5646',
  '1835-20 7718',
  '1842-24 5630',
  '1842-24 7718',
  '1845-24 7718',
  '1846-17 1180',
  '1846-17 2295',
  '1861-24 2295',
  '1863-20 7756',
  '1863-20 8874',
  '1870-20 2295',
  '1872-20 1180',
  '1872-20 2295',
  '1872-20 2380',
  '1872-20 7718',
  '1873-20 1180',
  '1873-20 2380',
  '1873-20 7718',
  '1875-17 7718',
  '2004-20 1002',
  '2005-20 1180',
  '2006-24 8016',
  '2011-18 2314',
  '2011-18 3397',
  '2011-22 2295',
  '2011-22 3397',
  '2011-24 2019',
  '2014-24 7718',
  '2015-24 8016',
  '2016-24 7718',
  '2017-22 1180',
  '2017-22 8016',
  '2018-24 1002',
  '2018-24 7718',
  '2018-24 8016',
  '2019-24 1002',
  '2019-24 7718',
  '2020-24 1002',
  '2023-24 7718',
  '2025-24 1002',
  '2026-24 1180',
  '2031-18 7718',
  '2036-18 3397',
  '2047-18 1004',
  '2047-18 2314',
  '2047-22 1004',
  '2047-22 2295',
  '2050-18 8830',
  '2202-20 1201',
  '2202-20 3301',
  '2202-20 6000',
  '2202-23 7718',
  '2203-20 3301',
  '2203-20 3397',
  '2203-20 6000',
  '2203-20 7718',
  '2204-20 2295',
  '2204-20 5620',
  '2204-20 6744',
  '2204-20 7720',
  '2205-20 3006',
  '2205-20 5620',
  '2205-20 6744',
  '2207-18 7718',
  '2208-18 7718',
  '2214-21 1201',
  '2214-21 6010',
  '2214-21 7718',
  '2216-21 1201',
  '2216-21 3301',
  '2216-21 7718',
  '2222-18 3301',
  '2222-18 3397',
  '2222-18 6010',
  '2222-18 6744',
  '2228-18 3301',
  '2228-18 5620',
  '2623-24 5625',
  '2624-24 2295',
  '2624-24 5625',
  '2637-24 2016',
  '2638-24 3301',
  '2639-24 3301',
  '2640-24 2301',
  '2647-24 5621',
  '2648-24 4412',
  '2652-24 2016',
  '2652-24 3006',
  '2653-24 6749',
  '2654-24 3001',
  '2657-24 3006',
  '2657-24 4411',
  '2657-24 6744',
  '2661-24 5620',
  '2665-24 2295',
  '2665-24 2301',
  '2665-24 6749',
  '2665-24 7718',
  '2665-24 9001',
  '2666-24 5621',
  '2666-24 6749',
  '2671-24 3006',
  '2671-24 5621',
  '2672-24 2295',
  '2672-24 3006',
  '2672-24 4412',
  '2672-24 8874',
  '2673-24 3301',
  '2674-24 3006',
  '2675-24 2295',
  '2675-24 3006',
  '2675-24 6744',
  '2676-24 2295',
  '2676-24 3006',
  '2676-24 6744',
  '2676-24 8874',
  '2677-24 2295',
  '2678-24 6744',
  '2691-24 2295',
  '2691-24 3301',
  '3405-24 2295',
  '3405-24 7718',
  '3405-24 9000',
  '3411-23 2295',
  '3411-23 8870',
  '3414-23 7718',
  '3414-23 9000',
  '3417-21 2009',
  '3417-21 7780',
  '3417-21 8882',
  '3417-21 9000',
  '3417-22 7718',
  '3420-18 2329',
  '3420-18 7700',
  '3420-18 8006',
  '3421-21 7718',
  '3421-21 9009',
  '3421-24 7700',
  '3422-21 9000',
  '3422-22 2295',
  '3426-24 6744',
  '3426-24 7718',
  '3426-24 8016',
  '3427-19 7718',
  '3442-24 7718',
  '3442-24 8016',
  '3455-24 2295',
  '4808-18 7718',
  '4814-21 3301',
  '5209-22 2295',
  '5209-22 3301',
  '5209-22 5621',
  '5210-22 5621',
  '5211-22 2295',
  '5229-23 2382',
  '5230-23 3005',
  '5802-24 3301',
  '5802-24 6000',
  '5803-23 5652',
  '5803-23 6012',
  '5805-23 5630',
  '5805-23 6000',
  '5805-23 7718',
  '5805-24 2011',
  '5805-24 3301',
  '5805-24 6621',
  '5805-24 7718',
  '5806-23 2015',
  '5806-24 2011',
  '5807-23 2011',
  '5808-23 7718',
  '5809-23 2011',
  '5809-23 7718',
  '5812-23 3301',
  '5812-23 7718',
  '5815-23 7718',
  '5816-23 7718',
  '5819-23 6012',
  '5819-23 7718',
  '5820-23 3301',
  '5820-23 5630',
  '5820-23 6000',
  '5820-23 7718',
  '5821-23 7718',
  '5822-23 2011',
  '5824-23 2011',
  '5825-23 7718',
  '5826-23 3301',
  '5826-23 6000',
  '5827-23 5630',
  '5827-23 6000',
  '5828-23 2011',
  '8221-21 0000',
  '8223-21 0000',
  '9601-16 0000',
  '9602-16 0000',
  '9608-18 7909',
  '9974-13 0000'
];

export const STORE_MAPPING = {
  LANDQUART: 'norrona-outlet-store-landquart',
  STMORITZ: 'norrona-partner-store-st.moritz',
  VERBIER: 'norrona-partner-store-verbier',
  ZERMATT: '',
  MUNICH: 'norrona-flagship-store-munich',
  VANTAA: '',
  'NPS-CHAMONIX': 'norrona-partner-store-chamonix',
  CORTINA: 'norrona-partner-store-cortina',
  ALGARD: 'norrona-outlet-store-algard',
  ALTA: 'norrona-concept-store-alta',
  BEITOSTOLEN: 'norrona-partner-store-beitostolen',
  BERGEN: 'norrona-concept-store-bergen',
  BODØ: 'norrona-concept-store-bodo',
  BOGSTADVEIEN: 'norrona-concept-store-BOGSTADVEIEN',
  CCVEST: '',
  CITYSYD: '',
  GULSKOGEN: 'norrona-concept-store-gulskogen',
  HAUGESUND: 'norrona-concept-store-haugesund',
  HEMSEDAL: 'norrona-partner-store-hemsedal',
  HQ: 'norrona-house-flagship',
  JESSHEIM: 'norrona-concept-store-jessheim',
  KRISTIANSAND: 'norrona-concept-store-kristiansand',
  KVADRAT: 'norrona-concept-store-kvadrat',
  LAGUNEN: 'norrona-concept-store-lagunen',
  LANGEVÅG: '',
  LILLEHAMMER: 'norrona-concept-store-lillehammer',
  MOA: 'norrona-concept-store-moa',
  MOLDE: '',
  OSLO: 'norrona-flagship-store-oslo',
  SANDVIKA: 'norrona-concept-store-sandvika',
  STAVANGER: 'norrona-concept-store-stavanger',
  STORO: 'norrona-concept-store-storo',
  STRØMMEN: 'norrona-concept-store-strommen',
  TROMSØ: 'norrona-concept-store-tromso',
  TRONDHEIM: 'norrona-concept-store-trondheim',
  VESTBY: '',
  ARE: 'norrona-pro-store-are',
  BARKARBY: '',
  FREEPORT: '',
  GOTEBORG: 'norrona-concept-store-goteborg',
  STOCKHOLM: 'norrona-flagship-store-stockholm',
  TABY: '',
  BOSTON: 'norrona-concept-store-boston',
  BOULDER: 'norrona-concept-store-boulder',
  CASTLEROCK: 'outlet-castle-rock',
  NEWYORK: 'norrona-flagship-store-new-york',
  USOUTLET: '',
  HELSINKI: 'norrona-outlet-store-helsinki',
  HEDE: 'norrona-outlet-store-hede'
};

export const MAP_DEFAULT_STYLES = [
  {
    featureType: 'administrative',
    elementType: 'all',
    stylers: [
    ]
  },
  {
    featureType: 'administrative.province',
    elementType: 'all',
    stylers: [
      {
        visibility: 'all'
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f7f7f7'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on'
      },
      {
        color: '#efefef'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        visibility: 'simplified'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e2e2e2'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on'
      },
      {
        color: '#ffffff'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#878787'
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  }
];
